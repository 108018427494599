const vars = {
  routes: {
    home: "/",
    nuestraUniversidad: "/servi",
    explora: "/offer",
    miEspacio: "/space",
  },
};

export default vars;
