const urls = Object.freeze({
    services: {
        university: "https://www.upn.edu.co/",
        news: "https://www.upn.edu.co/category/noticias/",
        library: "http://catalogo.upn.edu.co/",
        directory: "http://directorio.pedagogica.edu.co/",
        graduates: "http://centroegresados.pedagogica.edu.co/",
        repository: "http://repositorio.pedagogica.edu.co/",
        manosPensamiento: "http://manosypensamiento.upn.edu.co/",
        bienestar: "https://bienestar.upn.edu.co/",
        biblioteca: "https://biblioteca.upn.edu.co/",
        emisora: "https://radio.upn.edu.co/",
    },
    explore: {
        potcast: "http://radio.upn.edu.co/podcast/",
        emisora: "http://radio.upn.edu.co/",
        cinndet: "http://cinndet.upn.edu.co/",
        escuelaMaterna: "http://escuelamaternal.pedagogica.edu.co/",
    },
    mySpace: {
        moodle: "https://upnvirtual.pedagogica.edu.co/",
        sigan: "http://sigan.pedagogica.edu.co/sigan/",
        outlook: "https://outlook.office.com/mail/",
    },
    social: {
        facebook: "https://www.facebook.com/universidadpedagogicanacional.upn.9",
        twitter: "https://twitter.com/comunidadUPN",
        instagram: "https://www.instagram.com/upedagogicanacional/",
        youtube: "https://www.youtube.com/user/redacademica",
    },
    about: {
        PoliticaPrivacidad:
            "http://gobiernodigital.pedagogica.edu.co/wp-content/uploads/2018/04/manual_politica_tratamiento_datos_personales_upn.pdf",
        DerechosAutor:
            "https://www.upn.edu.co/wp-content/uploads/2022/10/acuerdo_011_CS_29junio2017_proteccion_propiedad_intelectual.pdf",
        TerminosCondiciones: "https://www.upn.edu.co/terminos-y-condiciones/",
        SGI: "https://upedagogicanacional.isolucion.co/PaginaLogin.aspx",
    },
});

export default urls;
