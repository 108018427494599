// Components
import LoadingPage from "../components/containers/LoadingPage.jsx";
import Header from "../components/organisms/header/Header.jsx";
import Footer from "../components/organisms/footer/Footer.jsx";
import Menu from "../components/organisms/menu/Menu.jsx";

// Vars
import vars from "../../_data/vars.js";
import background from "../../assets/img/fondo.jpg";
import universidad_pc from "../../assets/iconos/universidad_pc.svg";
import universidad_mb from "../../assets/iconos/universidad_mb.svg";
import explora_pc from "../../assets/iconos/explora_pc.svg";
import explora_mb from "../../assets/iconos/explora_mb.svg";
import miespacio_pc from "../../assets/iconos/espacio_pc.svg";
import miespacio_mb from "../../assets/iconos/espacio_mb.svg";

// Atoms
import HomeMainCircle from "../components/molecules/menu/HomeMainCircle.jsx";

const Home = () => {
    return (
        <>
            <LoadingPage />
            <Header />
            <Menu />
            <main className="home_main">
                <section className="home_main_article" style={{ backgroundImage: `url(${background})` }}>
                    <h1 className="home_main_title">Bienvenido al Campus Virtual</h1>
                    <h2 className="home_main_caption">Universidad Pedagógica Nacional</h2>

                    <p className="home_main_text">
                        {/* Aquí encontrarán una amplia gama de recursos y servicios que les ayudarán a mejorar sus
                        habilidades y a alcanzar sus metas académicas. ¡Estamos emocionados de acompañarlos en su camino
                        hacia el éxito académico! */}
                        Este espacio ha sido diseñado pensando en tus necesidades como estudiante o docente, ofreciéndote acceso a plataformas clave como el correo institucional, el Aula virtual y el sistema académico SIGAN, donde podrás consultar información, interactuar con tus profesores, gestionar tus cursos, entre otras funcionalidades
                    </p>

                    {/* <button className="home_main_btn">Presentación</button> */}
                </section>
                <section className="home_main_article_white">
                    <HomeMainCircle
                        link={vars.routes.nuestraUniversidad}
                        img={universidad_mb}
                        imgpc={universidad_pc}
                        text="Nuestra Universidad"
                    />
                    <HomeMainCircle link={vars.routes.explora} img={explora_mb} imgpc={explora_pc} text="Explora" />
                    <HomeMainCircle link={vars.routes.miEspacio} img={miespacio_mb} imgpc={miespacio_pc} text="Mi Espacio" />
                </section>
            </main>
            <Footer />
        </>
    );
};

export default Home;
