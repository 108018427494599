// Components
import ExploraSectionInfoContent from "./ExploraSectionInfoContent.jsx";
import React from 'react';
import EducationOfferingFilterAndCards from "./EducationOfferingFilterAndCards.jsx";

// Vars
import data from "../../../_data/database.json";


const PagesExplora = ({ explora }) => {
    return <EducationOfferingFilterAndCards explora={explora} />;
};

export default PagesExplora;
