// Components

// Vars
import imgRepo from "../../../assets/img/repo.png"; 

// Styles
import "../../styles/components/atoms/imgRepository.scss";

const RepositorioSectionImage = () => {
    return (
        <a href="http://repositorio.pedagogica.edu.co/" target="_blank" rel="noopener noreferrer" className="a-adjust">
                        <img className="adjust" src={imgRepo} alt="Inicio del repositorio" />
                    </a>
    );
};

export default RepositorioSectionImage;
