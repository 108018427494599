// Components
import ExploraSectionMenuItem from "../atoms/ExploraSectionMenuBtn.jsx";

import pregradoImg from '../../../assets/img/oferta/Principal/pregrado.png';
import posgradoImg from '../../../assets/img/oferta/Principal/posgrado.png';
import formacionContinuaImg from '../../../assets/img/oferta/Principal/Formacion-continua.png';

const ExploraSectionMenuNavList = () => {
    return (
        <ul className="btn-group explora_section_menu_nav_list" role="group" aria-label="Basic example">
            <ExploraSectionMenuItem text="Pregrados" imageUrl={pregradoImg} borderColor={"#29A5A5"}/>
            <ExploraSectionMenuItem text="Posgrados" imageUrl={posgradoImg} borderColor={"#0066D0"}/>
            <ExploraSectionMenuItem text="Formación Continua" imageUrl={formacionContinuaImg} borderColor={"#FE9178"}/>
            
        </ul>
    );
};

export default ExploraSectionMenuNavList;
