import { createContext, useContext } from "react";
import routes from "../../constants/routes.const";
import urls from "../../constants/urls.const";
import webServices from "../../constants/webServices.const";

const generalContext = createContext();

const GeneralContextProvider = ({ children }) => {
    const generalData = {
        routes,
        urls,
        webServices,
    };

    return <generalContext.Provider value={generalData}>{children}</generalContext.Provider>;
};

const useGeneralContext = () => {
    const context = useContext(generalContext);
    if (!context) {
        throw new Error("el contexto debe estar dentro del proveedor del contexto");
    }
    return context;
};

export { GeneralContextProvider, useGeneralContext };
