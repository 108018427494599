import PropTypes from "prop-types";

import ItemMenu from "../../atoms/menu/ItemMenu.jsx";

const ListMenu = ({ buttons }) => {
    const listMenuItem = buttons.map((item, id) => {
        return <ItemMenu key={id} link={item.link} icono={item.icono} text={item.text} />;
    });

    return <ul className="aside_menu_nav_list">{listMenuItem}</ul>;
};

ListMenu.propTypes = {
    buttons: PropTypes.array.isRequired,
};

export default ListMenu;
