/* import React from "react";
import { Carousel } from "react-responsive-carousel";

import "../../styles/components/atoms/BannerCarousel.scss";

const BannerCarousel = ({ items }) => {
  const onChange = (index, item) => {};
  const onClickItem = (index, item) => {};
  const onClickThumb = (index, item) => {};

  return (
    <Carousel
      showArrows={true}
      showThumbs={false}
      showStatus={false}
      swipeable={true}
      infiniteLoop={true}
      autoPlay={true}
      onChange={onChange}
      onClickItem={onClickItem}
      onClickThumb={onClickThumb}
      id="banner-carousel"
    >
      {items.map((item, index) => (
        <div key={index} className="banner-carousel-item">
          <img src={item.src} alt={item.alt} />
        </div>
      ))}
    </Carousel>
  );
};

export default BannerCarousel; */

import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import "../../styles/components/atoms/BannerCarousel.scss";

class DemoCarousel extends Component {
    render() {
        const { images } = this.props;
        return (
            <Carousel showArrows={true}
            showThumbs={false}
            showStatus={false}
            swipeable={true}
            infiniteLoop={true}
            autoPlay={true}
            id="banner-carousel">
                {images.map((image, index) => (
                    <div key={index} className="banner-carousel-item">
                    <img src={image.src} alt={image.alt} />
                    </div>
                ))}
            </Carousel>
        );
    }
}

export default DemoCarousel;