import { useState, useEffect } from "react";

const useScroll = (headerRef) => {
    const [initialHeight, setInitialHeight] = useState(0);
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        // Función para detectar si el tamaño de la pantalla es móvil o escritorio
        const updateInitialHeight = () => {
            if (window.matchMedia("(max-width: 568px)").matches) {
                // Si la pantalla es móvil (menos de 768px de ancho)
                setInitialHeight(47);
            } else {
                // Si la pantalla es de escritorio (más de 768px de ancho)
                setInitialHeight(0);
            }
        };

        // Llamamos la función al montar el componente para establecer el valor inicial
        updateInitialHeight();

        // Agregamos un listener para detectar cambios en el tamaño de la ventana
        window.addEventListener("resize", updateInitialHeight);

        const handleScroll = () => {
            const scrollY = window.scrollY;
            const nuevaAltura = Math.max(initialHeight - scrollY, 0);
            if (headerRef.current) {
                headerRef.current.style.top = `${nuevaAltura}px`;
            }
        };

        window.addEventListener("scroll", handleScroll);

        const onScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("scroll", onScroll);
            window.removeEventListener("resize", updateInitialHeight);
        };
    }, [headerRef, initialHeight]);

    return scrolled;
};

export default useScroll;