import { useState, useEffect } from "react";
import { imagesService } from "../../../../../services/ResourcesServices.js";

const useImages = (url) => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        (async () => {
            const data = await imagesService(url);
            setImages([data[9].archivo, data[5].archivo]);
        })();
    }, [url]);

    return images;
};

export default useImages;
