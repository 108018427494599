// Styles
import "../../styles/components/atoms/nuestrauNavList.scss";

const NuestrauNavList = ({ link, text, imageUrl }) => {
    const handleClick = () => {
        window.location.href = link;
    };

    const style = {
        backgroundImage: `url(${imageUrl})`,
    };

    return (
        <li className="nuestrau_nav_list_item" onClick={handleClick} style={style}>
            <a href={link} target="_blank" rel="noreferrer">
                {text}
            </a>
        </li>
    );
};

export default NuestrauNavList;
