import { useRef, useEffect, useCallback } from "react";

// Styles
import "../../styles/components/containers/nuestrauImagesPresentation.scss";

// Components
import NuestrauCarrilImagenesItem from "../atoms/NuestrauCarrilImagenesItem.jsx";
import NuestrauImagesSlide from "../molecules/NuestrauImagesSlide.jsx";

// Vars
import background from "../../../assets/img/fondoUniversidad/fondo.jpg";
import background0 from "../../../assets/img/fondoUniversidad/fondo0.jpg";
import background1 from "../../../assets/img/fondoUniversidad/fondo1.jpg";
import background2 from "../../../assets/img/fondoUniversidad/fondo2.jpg";
import background3 from "../../../assets/img/fondoUniversidad/fondo3.jpg";
import background4 from "../../../assets/img/fondoUniversidad/fondo4.jpg";
import background5 from "../../../assets/img/fondoUniversidad/fondo5.jpg";
import background6 from "../../../assets/img/fondoUniversidad/fondo6.jpg";
import background7 from "../../../assets/img/fondoUniversidad/fondo7.jpg";
import background8 from "../../../assets/img/fondoUniversidad/fondo8.jpg";
import background9 from "../../../assets/img/fondoUniversidad/fondo9.jpg";
import background10 from "../../../assets/img/fondoUniversidad/fondo10.jpg";
import background11 from "../../../assets/img/fondoUniversidad/fondo11.jpg";
import background12 from "../../../assets/img/fondoUniversidad/fondo12.jpg";
import background13 from "../../../assets/img/fondoUniversidad/fondo13.jpg";
import background14 from "../../../assets/img/fondoUniversidad/fondo14.jpg";
import background15 from "../../../assets/img/fondoUniversidad/fondo15.jpg";
import background16 from "../../../assets/img/fondoUniversidad/fondo16.jpg";
import background17 from "../../../assets/img/fondoUniversidad/fondo17.jpg";
import background18 from "../../../assets/img/fondoUniversidad/fondo18.jpg";

const NuestrauImagesPresentation = ({ velocidad = "400", intervalo = "5000" }) => {
    const slideshow = useRef(null);
    const intervaloSlideshow = useRef(null);

    const pasarImagen = useCallback(() => {
        if (slideshow.current && slideshow.current.children.length > 0) {
            const primerElemento = slideshow.current.children[0];

            slideshow.current.style.transition = `${velocidad}ms ease-out all`;
            const tamañoSlide = slideshow.current.children[0].offsetWidth;

            slideshow.current.style.transform = `translateX(-${tamañoSlide}px)`;

            const transicion = () => {
                slideshow.current.style.transition = "none";
                slideshow.current.style.transform = `translateX(0)`;
                slideshow.current.appendChild(primerElemento);
                slideshow.current.removeEventListener("transitionend", transicion);
            };

            slideshow.current.addEventListener("transitionend", transicion);
        }
    }, [velocidad]);

    useEffect(() => {
        if (slideshow.current && slideshow.current.children.length > 0) {
            intervaloSlideshow.current = setInterval(() => {
                pasarImagen();
            }, intervalo);

            slideshow.current.addEventListener("mouseenter", () => {
                clearInterval(intervaloSlideshow.current);
            });
        }

        return () => {
            clearInterval(intervaloSlideshow.current);
        };
    }, [intervalo, pasarImagen]);

    return (
        <section className="nuestrau_section_first">
            <h1 className="nuestrau_images_title">Servicios</h1>
            <article className="container_images_presentation" ref={slideshow}>
                <NuestrauImagesSlide img={background} />
                <NuestrauImagesSlide img={background0} />
                <NuestrauImagesSlide img={background1} />
                <NuestrauImagesSlide img={background2} />
                <NuestrauImagesSlide img={background3} />
                <NuestrauImagesSlide img={background4} />
                <NuestrauImagesSlide img={background5} />
                <NuestrauImagesSlide img={background6} />
                <NuestrauImagesSlide img={background7} />
                <NuestrauImagesSlide img={background8} />
                <NuestrauImagesSlide img={background9} />
                <NuestrauImagesSlide img={background10} />
                <NuestrauImagesSlide img={background11} />
                <NuestrauImagesSlide img={background12} />
                <NuestrauImagesSlide img={background13} />
                <NuestrauImagesSlide img={background14} />
                <NuestrauImagesSlide img={background15} />
                <NuestrauImagesSlide img={background16} />
                <NuestrauImagesSlide img={background17} />
                <NuestrauImagesSlide img={background18} />
            </article>
            <article className="nuestrau_images_presentation_ui">
                <div className="nuestrau_carril_imagenes">
                    <NuestrauCarrilImagenesItem />
                    <NuestrauCarrilImagenesItem />
                    <NuestrauCarrilImagenesItem />
                    <NuestrauCarrilImagenesItem />
                    <NuestrauCarrilImagenesItem />
                    <NuestrauCarrilImagenesItem />
                    <NuestrauCarrilImagenesItem />
                    <NuestrauCarrilImagenesItem />
                </div>
                <a className="nuestrau_link_universidad" href="https://www.upn.edu.co/">
                    La Universidad
                </a>
            </article>
        </section>
    );
};

export default NuestrauImagesPresentation;