import React, { useState, useEffect } from "react";
import ButtonAnchor from "../../components/atoms/ButtonAnchor";
import Card from "../../components/atoms/Card";
import educationalOfferingCourses from "../../../_data/educational_offering_courses.json";
import facultiesConfig from "../../../constants/facultiesConfig"; // Importa el archivo facultiesConfig.js
import "../../styles/containers/EducationOfferingFilterAndCards.scss"; // Asegúrate de que este archivo esté correctamente importado

const EducationOfferingFilterAndCards = ({ explora }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState(""); // Nuevo estado para la facultad
  const coursesPerPage = 3;

  // Definir categorías válidas
  const validCategories = ["Pregrados", "Posgrados", "Formación Continua"];

  // Obtener todas las facultades de los cursos filtrados por categoría
  const faculties = [...new Set(
    educationalOfferingCourses
      .filter((course) => course.category === explora)
      .map((course) => course.faculty)
  )];

  useEffect(() => {
    // Filtrar los cursos que coinciden con la categoría seleccionada
    let filteredByCategory = educationalOfferingCourses.filter(
      (course) => course.category === explora
    );

    // Si hay una facultad seleccionada, filtrar los cursos por facultad
    if (selectedFaculty) {
      filteredByCategory = filteredByCategory.filter(
        (course) => course.faculty === selectedFaculty
      );
    }

    // Aplicar filtro de búsqueda
    const filtered = filteredByCategory.filter((course) =>
      course.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredCourses(filtered);
  }, [searchQuery, explora, selectedFaculty]);

  const numberOfPages = Math.ceil(filteredCourses.length / coursesPerPage);

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= numberOfPages) {
      setCurrentPage(pageNumber);
    }
  };

  const getPageNumbers = () => {
    const allPageNumbers = [];
    for (let i = 1; i <= numberOfPages; i++) {
      allPageNumbers.push(i);
    }
    return allPageNumbers;
  };

  const renderPagination = () => {
    const allPageNumbers = getPageNumbers();

    return allPageNumbers.map((pageNumber) => (
      <button
        key={pageNumber}
        onClick={() => handlePageChange(pageNumber)}
        disabled={pageNumber === currentPage}
        className={pageNumber === currentPage ? "page_selected_button" : ""}
      >
        {pageNumber}
      </button>
    ));
  };

  const indexOfLastCourse = currentPage * coursesPerPage;
  const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
  const currentCourses = filteredCourses.slice(
    indexOfFirstCourse,
    indexOfLastCourse
  );

  // Renderizado de botones de facultades con fondo y borde
  const renderFacultiesButtons = () => {
    return faculties.map((faculty, index) => {
      const facultyConfig = facultiesConfig[faculty];
      const localImage = require(`../../../assets/img/oferta/Facultades/${facultyConfig?.backgroundImage}`);
      const style = {
        backgroundImage: `url(${localImage || "none"})`,
        border: `2px solid ${facultyConfig?.borderColor || "#ccc"}`
      };

      return (
        <button
          key={index}
          onClick={() => setSelectedFaculty(faculty)}
          className={selectedFaculty === faculty ? "active" : ""}
          style={style}
        >
          {faculty}
        </button>
      );
    });
  };

  return (
    <div className="education_offering_filter_and_cards">
      {/* Mostrar barra de búsqueda solo si se selecciona una categoría válida */}
      {validCategories.includes(explora) && (
        <>
          <div className="search_courses">
            <input
              type="text"
              placeholder="Buscar"
              onChange={handleInputChange}
            />
            <ion-icon name="search-outline"></ion-icon>
          </div>

          {/* Mostrar botón "Mostrar todos" solo si hay una facultad seleccionada */}
          {selectedFaculty && (
            <button
              onClick={() => setSelectedFaculty("")} // Restablecer el filtro de facultad
              className="show-all-button"
            >
              Mostrar todos
            </button>
          )}

          {/* Renderizar los botones de facultades solo si NO es "Formación Continua" */}
          {explora !== "Formación Continua" && (
            <div className="faculties_filter">
              {renderFacultiesButtons()}
            </div>
          )}
        </>
      )}

      <div className="courses_paginator">
        {currentCourses.map((course, index) => (
          <Card
            key={index}
            title={course.title}
            description={course.description}
            imageSrc={course.imageSrc}
            imageAlt={course.imageAlt}
            redirect={course.redirect}
            color={course.color}
            hours={course.hours}
            modules={course.modules}
            button_text={course.button_text}
          />
        ))}
      </div>

      <div className="paginator_buttons">
        {renderPagination()}
      </div>
    </div>
  );
};

export default EducationOfferingFilterAndCards;