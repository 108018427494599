// Styles
import "../../styles/components/containers/miEspacioSectionCard.scss";

const MiEspacioSectionCard = ({ img, title, icon, text, link }) => {
    return (
        <article className="mi_espacio_section_card" style={{ backgroundImage: `url(${img})` }}>
            <div className="mi_espacio_section_card_gradiente">
                <div className="mi_espacio_section_card_container_title">
                    <h2>{title}</h2>
                </div>
                <figure>
                    <ion-icon name={icon}></ion-icon>
                </figure>
                <p>{text}</p>
                <a href={link} target="_blank" rel="noreferrer">
                    Acceder
                </a>
            </div>
        </article>
    );
};

export default MiEspacioSectionCard;
