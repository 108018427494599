
const facultiesConfig = {
    "Facultad de Bellas Artes": {
      backgroundImage: `Fct-Artes_.png`,
      borderColor: "#e52538"
    },
    "Facultad de Ciencia y Tencología": {
      backgroundImage: `Fct-Ciencia-y-tecnologia.png`,
      borderColor: "#ffd100"
    },
    "Facultad de Educación": {
      backgroundImage: `Fct-Educacion.png`,
      borderColor: "#8cc63f"
    },
    "Facultad de Educación Física": {
      backgroundImage: `Fct-edu-fisica.png`,
      borderColor: "#9d1d96"
    },
    "Facultad de Humanidades": {
      backgroundImage: `Fct-Humanidades.png`,
      borderColor: "#ff9e18"
    }
  };
  
  export default facultiesConfig;