// Components
import ExploraSectionInfoContent from "./ExploraSectionInfoContent.jsx";
import React from 'react';
import ContactCards from "./ContactCards.jsx";

// Vars
import data from "../../../_data/database.json";


const PagesContact = ({ explora }) => {
    return <ContactCards explora={explora} />;
};

export default PagesContact;