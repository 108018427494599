import { useState, useEffect } from "react";
import { govHeaderService } from "../../../../../services/ResourcesServices.js";

const useGov = (url) => {
    const [govHeader, setGovHeader] = useState("loading");

    useEffect(() => {
        (async () => {
            const data = await govHeaderService(url);
            setGovHeader(data);
        })();
    }, [url]);

    return govHeader;
};

export default useGov;
