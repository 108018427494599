export async function fetchData(url = "", options = {}) {
    try {
        const response = await fetch(url, options);

        if (!response.ok) {
            const errorMessage = `Error en la solicitud: ${response.status} ${response.statusText}`;
            throw new Error(errorMessage);
        }

        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
            throw new Error("La respuesta no es de tipo JSON");
        }

        const jsonData = await response.json();
        return jsonData;
    } catch (error) {
        console.error("Hubo un error en la llamada fetch:", error.message);
        throw error;
    }
}
