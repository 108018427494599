import { useState } from "react";

const useMenu = () => {
    const [menu, setMenu] = useState(false);

    const handleMenu = () => {
        setMenu((prevMenu) => !prevMenu);
    };

    return { menu, handleMenu };
};

export default useMenu;
