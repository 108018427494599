// Components
import InstitutionalFooter from "../../atoms/footer/InstitutionalFooter.jsx";
import GovFooter from "../../atoms/footer/GovFooter.jsx";
import FooterRings from "../../molecules/footer/FooterRings.jsx";

// Context
import { useGeneralContext } from "../../../../models/context/GeneralContext.jsx";

const Footer = () => {
    const { webServices } = useGeneralContext();

    return (
        <footer>
            <InstitutionalFooter url={webServices.urlFooter} />
            <GovFooter url={webServices.urlFooter2} />
            <FooterRings text="VERSIÓN 2.0 COPYRIGHT © 2023 UNIVERIDAD PEDAGÓGICA NACIONAL TODOS LOS DERECHOS RESERVADOS" />
        </footer>
    );
};

export default Footer;
