import React from "react";
import "../../styles/components/atoms/Card.scss";


const Card = ({
  title,
  description,
  imageSrc,
  imageAlt,
  redirect,
  color,
  hours,
  modules,
  button_text,
}) => {
  const localImage = require(`../../../assets/img/oferta/${imageSrc}`);
  return (
    <article className="course" style={{ border: `1px solid ${color}` }}>
      <h3 style={{ backgroundColor: `${color}` }}>{title}</h3>
      <figure>
      <img src={localImage} alt={imageAlt} />
        <img alt=""/>
      </figure>
      <article
        className="course_details"
        style={{ borderTop: `6px solid ${color}` }}
      >
        <div>
          <article className="first_course_info">
            <ion-icon name="logo-buffer"></ion-icon>
            <p>{`${hours}`}</p>
          </article>
          <article className="first_course_info">
            <ion-icon name="logo-buffer"></ion-icon>
            <p>{`${modules}`}</p>
          </article>
        </div>
        <p>{description}</p>
        <a href={redirect} style={{ backgroundColor: `${color}` }}>
          {button_text}
        </a>
      </article>
    </article>
  );
};

export default Card;
