import { useState, useEffect } from "react";
import { footerService } from "../../../../../services/ResourcesServices.js";

const useFooter = (url) => {
    const [footer, setFooter] = useState("loading");

    useEffect(() => {
        (async () => {
            const data = await footerService(url);
            setFooter(data);
        })();
    }, [url]);

    return footer;
};

export default useFooter;
