import React from "react";

// Styles
import "../menu/homeMainCircle.scss";

const HomeMainCircle = ({ link, img }) => {
  return (
    <div className="home_main_circle_container">
      <a href={link} className="home_main_circle">
        <div className="home_main_circle_figure" style={{ backgroundImage: `url(${img})` }}></div>
      </a>
    </div>
  );
};

export default HomeMainCircle;
