import { useState, createContext } from "react";

// Styles
import "../styles/pages/offer.scss";

// Components
import LoadingPage from "../components/containers/LoadingPage.jsx";
import Header from "../components/organisms/header/Header.jsx";
import Footer from "../components/organisms/footer/Footer.jsx";
import Menu from "../components/organisms/menu/Menu.jsx";

import ExploraSectionMenuNavList from "../components/containers/ExploraSectionMenuNavList.jsx";
import PagesExplora from "../components/containers/PagesExplora.jsx";
// Vars

//Containers
import BannerCarrouselEducationOffering from "../components/containers/BannerCarrouselEducationOffering.jsx";
import EducationOfferingFilterAndCards from "../components/containers/EducationOfferingFilterAndCards.jsx";

// * Lógica de la página:

/* Se crea un contexto para poder acceder al estado que controla las secciones de la página 
(cinndet, comunicaciones, radio, biblioteca, editorial, o cualquier otra que decidan agregar)
y poder hacer la actualización del estado mediante un componente hijo */

export const exploraContext = createContext(null);

const Offer = () => {
    const [explora, setexplora] = useState("Null");
    return (
        <exploraContext.Provider value={{ explora, setexplora }} className="body">
            
        <div className="body">
            <LoadingPage />
            <Header />
            <Menu />
            <main className="explora_main">
                <div className="explora_separador"></div>
                <h1 className="explora_title">Oferta Educativa</h1>
                <section className="explora_section">
                    <BannerCarrouselEducationOffering />
                    <nav className="explora_section_menu_nav">
                        <ExploraSectionMenuNavList />
                    </nav>
                    <PagesExplora explora={explora} />
                </section>
            </main>
            <Footer />
        </div>
        </exploraContext.Provider>
    );
};

export default Offer;

