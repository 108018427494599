/* import { Link } from "react-router-dom";

import UseItemMenu from "./hooks/UseItemMenu";

const ItemMenu = ({ link, icono, text }) => {
    const subpath = UseItemMenu();

    return (
        <Link to={link} className={`aside_menu_item ${subpath === link ? "active" : "desactive"}`} >
            <li className={`aside_menu_item_links ${subpath === link ? "active" : "desactive"}`} data-title={text}>
                <ion-icon name={icono} title="{text}"></ion-icon>
                <p>{text}</p>
            </li>
        </Link>
    );
};

export default ItemMenu; */
import { Link } from "react-router-dom";
import UseItemMenu from "./hooks/UseItemMenu";

const ItemMenu = ({ link, icono, text }) => {
    const subpath = UseItemMenu();

    return (
        <Link to={link} className={`aside_menu_item ${subpath === link ? "active" : "desactive"}`}>
            <li className={`aside_menu_item_links ${subpath === link ? "active" : "desactive"}`} data-title={text}>
                <ion-icon name={icono} title={text}></ion-icon>
                <p>{text}</p>
            </li>  
        </Link>
    );
};

export default ItemMenu;