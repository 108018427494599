import React from "react";

const ContactCards = ({ explora }) => {
  const information = {
    "Soporte Moodle": {
      title: "Soporte Moodle",
      description: "Si requieres ayuda con tu usuario de Moodle, envía un mensaje a:",
      email: "cursosmoodle@upn.edu.co",
      instructions: [
        "Nombre completo.",
        "Correo institucional.",
        "Número de identificación.",
        "Código.",
        "Programa al que perteneces.",
        "Número telefónico."
      ]
    },
    "Soporte Correo insitucional": {
      title: "Soporte Correo Institucional",
      description: "Para problemas con tu correo institucional, por favor contacta a:",
      email: "soportecorreo@upn.edu.co",
      instructions: [
        "Tu nombre completo.",
        "Tu dirección de correo institucional.",
        "Una descripción detallada del problema."
      ]
    },
    "Atención en oficina": {
      title: "Atención en Oficina",
      columns: [
        {
          title: "Soporte Moodle",
          address: "Cl. 72 #11-86, Bogotá Oficina: B-128",
          support: "Soporte y apoyo técnico",
          email: "cursosmoodle@upn.edu.co",
          contactEmail: "cinndet@upn.edu.co",
          phone: "Telefono: 5941894 ext. 326 / 327",
          hours: "Horario: lunes a viernes de 8:00 a.m. a 12:00 p.m. y de 2:00 p.m. a 5:00 p.m."
        },
        {
          title: "Soporte Correo institucional",
          address: "Cl. 72 #11-86, Bogotá Oficina: B-128",
          support: "Soporte y apoyo técnico",
          email: "cursosmoodle@upn.edu.co",
          contactEmail: "cinndet@upn.edu.co",
          phone: "Telefono: 5941894 ext. 326 / 327",
          hours: "Horario: lunes a viernes de 8:00 a.m. a 12:00 p.m. y de 2:00 p.m. a 5:00 p.m."
        }
      ]
    }
  };

  const selectedInfo = information[explora];

  if (!selectedInfo) {
    return <p></p>;
  }

  // Si la opción seleccionada es "Atención en oficina", mostrar las columnas
  if (explora === "Atención en oficina") {
    return (
      <div className="contact_info_columns">
        {selectedInfo.columns.map((column, index) => (
          <div key={index} className="contact_column">
            <h2>{column.title}</h2>
            <p>
            Dirección: {column.address}
            </p>
            <p>
              {column.support}
              <br />
              UPNVirtual: {column.email}
              <br />
              Contáctanos: {column.contactEmail}
              <br />
              {column.phone}
              <br />
              {column.hours}
            </p>
          </div>
        ))}
      </div>
    );
  }

  // Si la opción seleccionada es "Soporte Moodle" o "Soporte Correo Institucional", mostrar la información normal
  return (
    <div className="contact_info">
      <h2>{selectedInfo.title}</h2>
      <p>{selectedInfo.description}</p>
      {selectedInfo.email && (
        <p>
          <a href={`mailto:${selectedInfo.email}`}>{selectedInfo.email}</a>
        </p>
      )}
      {selectedInfo.instructions && (
        <ul>
          {selectedInfo.instructions.map((instruction, index) => (
            <li key={index}>{instruction}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ContactCards;