import Home from "../../ui/pages/Home.jsx";
import Services from "../../ui/pages/Services.jsx";
import Offer from "../../ui/pages/Offer.jsx";
import Repository from "../../ui/pages/Repository.jsx";
import MySpace from "../../ui/pages/MySpace.jsx";
import Contact from "../../ui/pages/Contact.jsx";
import NotFound from "../../ui/pages/NotFound.jsx";
import routes from "../../constants/routes.const.js";

const routesList = [
    {
        path: routes.home,
        component: Home,
    },
    {
        path: routes.services,
        component: Services,
    },
    {
        path: routes.offer,
        component: Offer,
    },
    {
        path: routes.repository,
        component: Repository,
    },
    {
        path: routes.mySpace,
        component: MySpace,
    },
    {
        path: routes.contact,
        component: Contact,
    },
    {
        path: "*",
        component: NotFound,
    },
];

export default routesList;
