import React from "react";

const FooterRings = ({ text }) => {
    return (
        <section className="footer-rights">
            <p>{text}</p>
        </section>
    );
};

export default FooterRings;
