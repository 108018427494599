import { useContext } from "react";

import { exploraContext } from "../../pages/Offer.jsx";

const ExploraSectionMenuBtn = ({ text, imageUrl, borderColor }) => {
    const contexto = useContext(exploraContext);

    const claseActiveState = contexto.explora === text ? "btn btn-primary btn_active_state" : "btn btn-primary";


    const style = {
        backgroundImage: `url(${imageUrl})`,
        border: `2px solid ${borderColor}`,
    };

    return (
        <button
            onClick={() => {
                contexto.setexplora(text);
            }}
            type="button"
            className={claseActiveState}
            style={style}
        >
            {text}
        </button>
    );
};

export default ExploraSectionMenuBtn;

