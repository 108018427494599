const routes = Object.freeze({
    home: "/",
    services: "/servi",
    offer: "/offer",
    repository: "/repository",
    mySpace: "/space",
    contact: "/contact",
});

export default routes;
