import PropTypes from "prop-types";

const BtnLogin = ({ text, icon, url }) => {
    const handleClick = () => {
        window.location.href = url;
    };

    return (
        <button className="btn_login" onClick={handleClick}>
            <ion-icon name={icon}></ion-icon>
            <span>{text}</span>
        </button>
    );
};

BtnLogin.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired, // Nueva prop
};

export default BtnLogin;