// Styles
import "./menu.scss";

// Components
import BtnMenu from "../../molecules/menu/BtnMenu.jsx";
import ListMenu from "../../molecules/menu/ListMenu.jsx";

// Hooks
import useMenu from "./hooks/useMenu.jsx";

// Context
import { useGeneralContext } from "../../../../models/context/GeneralContext.jsx";

const Menu = () => {
    const { routes } = useGeneralContext();
    const { menu, handleMenu } = useMenu();

    const btnsNav = [
        { text: "Inicio", link: routes.home, icono: "home", type: "route" },
        { text: "Oferta", link: routes.offer, icono: "school", type: "route" },
        { text: "Servicios", link: routes.services, icono: "fitness", type: "route" },
        { text: "Mi Espacio", link: routes.mySpace, icono: "book", type: "route" },
        
    ];

    const btnsFooter = [
        { text: "Contacto", link: routes.contact, icono: "call", type: "route" },
        /* { text: "Configuración", link: routes.university, icono: "settings", type: "button" }, */
        /* { text: "Cerrar Sesión", link: routes.explore, icono: "log-out", type: "button" }, */
    ];

    return (
        <aside className={`aside_menu ${menu}`}>
            <nav className="aside_menu_nav">
                <BtnMenu text="Menú de navegación" aside={menu} handleMenu={handleMenu} />
                <ListMenu buttons={btnsNav} />
                <ListMenu buttons={btnsFooter} />
            </nav>
        </aside>
    );
};

export default Menu;
