import { fetchData } from "../utils/fetchData";

const govHeaderService = async (url) => {
    const govHeader = await fetchData(url);
    return govHeader.gov_co;
};

const imagesService = async (url) => {
    const images = await fetchData(url);
    return images;
};

const footerService = async (url) => {
    const footer = await fetchData(url);
    return footer.footer;
};

export { govHeaderService, imagesService, footerService };
