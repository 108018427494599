import { useState, createContext } from "react";

// Components
import LoadingPage from "../components/containers/LoadingPage.jsx";
import Header from "../components/organisms/header/Header.jsx";
import Footer from "../components/organisms/footer/Footer.jsx";
import Menu from "../components/organisms/menu/Menu.jsx";

import RepositorioSectionImage from "../components/atoms/RepositorioSectionImage.jsx";

// Styles
import "../styles/pages/repository.scss";
import "../styles/pages/offer.scss";

   

export const exploraContext = createContext(null);

const Repository = () => {
    const [explora, setexplora] = useState("Divulgación");
    return (
        <exploraContext.Provider value={{ explora, setexplora }} className="body">
            <LoadingPage />
            <Header />
            <Menu />

            <main className="repository_main">
                <div className="repository_separador"></div>
                <h1 className="repository_title">Repository</h1>
                <section className="explora_section">
                    <RepositorioSectionImage/>{/* 
                    <nav className="explora_section_menu_nav">
                        <ExploraSectionMenuNavList />
                    </nav>
                    <PagesExplora explora={explora} /> */}
                </section>
            </main>

            <Footer />
        </exploraContext.Provider>
    );
};

export default Repository;

