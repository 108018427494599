import PropTypes from "prop-types";

const LogoUpn = ({ logoSmall, logoLarge }) => {
    return (
        <div className="header_logo_upn">
            <a href="https://www.upn.edu.co/" target="_blank" rel="noopener noreferrer">
                <img className="small" src={logoSmall} alt="Logo de la UPN" />
                <img className="large" src={logoLarge} alt="Logo de la UPN - Texto" />
            </a>
            <a href="/" className="text">Campus Virtual</a>
        </div>
    );
};

LogoUpn.propTypes = {
    logoSmall: PropTypes.string.isRequired,
    logoLarge: PropTypes.string.isRequired,
};

export default LogoUpn;
