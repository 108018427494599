import PropTypes from "prop-types";

const BtnMenu = ({ text, aside, handleMenu }) => {
    return (
        <figure className="menu-icon">
            <button className="grid-icon" onClick={handleMenu}>
                <ion-icon name="grid"></ion-icon>
                <span>{text}</span>
            </button>

            <button className="handler-icon" onClick={handleMenu}>
                {aside ? (
                    <ion-icon name="chevron-back-circle"></ion-icon>
                ) : (
                    <ion-icon name="chevron-forward-circle"></ion-icon>
                )}
            </button>
        </figure>
    );
};

BtnMenu.propTypes = {
    text: PropTypes.string.isRequired,
    aside: PropTypes.bool.isRequired,
    handleMenu: PropTypes.func.isRequired,
};

export default BtnMenu;
