import NuestrauImagesGradiente from "../atoms/NuestrauImagesGradiente.jsx";

const NuestrauImagesSlide = ({ img, referencia }) => {
    return (
        <div className="nuestrau_images_slide" style={{ backgroundImage: `url(${img})` }} ref={referencia}>
            <NuestrauImagesGradiente />
        </div>
    );
};

export default NuestrauImagesSlide;
