import { useEffect, useState } from "react";

const UseItemMenu = () => {
    const [subpath, setSubpath] = useState("");

    useEffect(() => {
        setSubpath(window.location.pathname);
    }, []);

    return subpath;
};

export default UseItemMenu;
