import { BrowserRouter, Routes } from "react-router-dom";
import { GeneralContextProvider } from "../models/context/GeneralContext";
import getRoutes from "../services/RoutesService";

import "./styles/global.scss";

const App = () => {
    return (
        <GeneralContextProvider>
            <BrowserRouter>
                <Routes>{getRoutes()}</Routes>
            </BrowserRouter>
        </GeneralContextProvider>
    );
};

export default App;
