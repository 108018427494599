// Styles
import "../styles/pages/services.scss";

// Components
import NuestrauNavList from "../components/atoms/NuestrauNavList.jsx";
import LoadingPage from "../components/containers/LoadingPage.jsx";
import Header from "../components/organisms/header/Header.jsx";
import Footer from "../components/organisms/footer/Footer.jsx";
import Menu from "../components/organisms/menu/Menu.jsx";
import NuestrauImagesPresentation from "../components/containers/NuestrauImagesPresentation.jsx";

// Context
import { useGeneralContext } from "../../models/context/GeneralContext.jsx";

import bienestarImg from '../../assets/img/services/Bienestar.jpg';
import directorioImg from '../../assets/img/services/Directorio.jpg';
import egresadosImg from '../../assets/img/services/Egresados.jpg';
import noticiasImg from '../../assets/img/services/Noticias.jpg';
import bibliotecaImg from '../../assets/img/services/Repositorio.jpg';
import emisoraImg from '../../assets/img/services/Emisora.jpg';

const Services = () => {
    const { urls } = useGeneralContext();

    return (
        <>
            <LoadingPage />
            <Header />
            <Menu />
            <main className="nuestrau_main">
                <section className="nuestrau_fondo">
                    <NuestrauImagesPresentation />
                    <nav className="nuestrau_nav">
                        <ul className="nuestrau_nav_list">
                            <NuestrauNavList link={urls.services.bienestar} text="Bienestar" imageUrl={bienestarImg}/>
                            <NuestrauNavList link={urls.services.news} text="Noticias" imageUrl={noticiasImg}/>
                            <NuestrauNavList link={urls.services.directory} text="Directorio" imageUrl={directorioImg}/>
                            <NuestrauNavList link={urls.services.graduates} text="Egresados" imageUrl={egresadosImg}/>
                            <NuestrauNavList link={urls.services.biblioteca} text="Biblioteca" imageUrl={bibliotecaImg}/>
                            <NuestrauNavList link={urls.services.emisora} text="Emisora" imageUrl={emisoraImg}/>
                        </ul>
                    </nav>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default Services;
