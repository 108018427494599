import { Route } from "react-router-dom";
import routesList from "../models/routes/routesList.js";

const getRoutes = () => {
    return routesList.map((route, index) => {
        return <Route key={index} path={route.path} exact element={<route.component />} />;
    });
};

export default getRoutes;
