// Components
import ContactSectionMenuItem from "../atoms/ContactSectionMenuBtn.jsx";

import correoImg from '../../../assets/img/contact/Soporte-Correo.png';
import moodleImg from '../../../assets/img/contact/Soporte-Moodle.png';
import oficinaImg from '../../../assets/img/contact/Soporte-Oficina.png';

const ContactSectionMenuNavList = () => {
    return (
        <ul className="btn-group contact_section_menu_nav_list" role="group" aria-label="Basic example">
            <ContactSectionMenuItem text="Soporte Moodle" imageUrl={moodleImg} />
            <ContactSectionMenuItem text="Soporte Correo insitucional" imageUrl={correoImg} />
            <ContactSectionMenuItem text="Atención en oficina" imageUrl={oficinaImg} />
            
        </ul>
    );
};

export default ContactSectionMenuNavList;
