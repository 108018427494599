import { useState, createContext } from "react";

// Styles
import "../styles/pages/contact.scss";

// Components
import LoadingPage from "../components/containers/LoadingPage.jsx";
import Header from "../components/organisms/header/Header.jsx";
import Footer from "../components/organisms/footer/Footer.jsx";
import Menu from "../components/organisms/menu/Menu.jsx";

import ContactSectionMenuNavList from "../components/containers/ContactSectionMenuNavList.jsx";
import PagesContact from "../components/containers/PagesContact.jsx";

// Vars

// * Lógica de la página:

/* Se crea un contexto para poder acceder al estado que controla las secciones de la página 
(cinndet, comunicaciones, radio, biblioteca, editorial, o cualquier otra que decidan agregar)
y poder hacer la actualización del estado mediante un componente hijo */

export const exploraContext = createContext(null);

const Offer = () => {
    const [explora, setexplora] = useState("Null");
    return (
        <exploraContext.Provider value={{ explora, setexplora }} className="body">
            
        <div className="body">
            <LoadingPage />
            <Header />
            <Menu />
            <main className="contact_main">
                <div className="contact_separador"></div>
                <h1 className="contact_title">Soporte</h1>
                <p className="mi_espacio_text">
                Escoge la modalidad de soporte que necesitas.
                </p>
                <section className="contact_section">
                    <nav className="contact_section_menu_nav">
                        <ContactSectionMenuNavList />
                    </nav>
                    <PagesContact explora={explora} />
                </section>
            </main>
            <Footer />
        </div>
        </exploraContext.Provider>
    );
};

export default Offer;

