// Styles
import "../styles/pages/notFound.scss";

// Componentes
import LoadingPage from "../components/containers/LoadingPage.jsx";

// Variables
import rutaImagen404 from "../../assets/img/img404.svg";

const NotFound = () => {
    return (
        <div className="not_found_container">
            <LoadingPage />
            <h1 className="not_found_title">404</h1>
            <img className="not_found_img" src={rutaImagen404} alt="Imagen del error" />
            <p className="not_found_text">
                Ups.. esta aula no existe, ingresa a nuestro Campus Virtual de nuevo... <br /> <span>🫣</span>
            </p>
            <a className="not_found_btn" href="/">
                Regresar al inicio
            </a>
        </div>
    );
};

export default NotFound;
