/* import React from 'react';
import BannerCarousel from '../../components/atoms/BannerCarousel';
import educationalOfferingBannerImages from '../../../_data/educational_offering_banner_images.json';

const BannerCarrouselEducationOffering = () => {
    return (
        <BannerCarousel items={educationalOfferingBannerImages} />
    );
};

export default BannerCarrouselEducationOffering; */

import React from 'react';
import DemoCarousel from '../../components/atoms/BannerCarousel';
import educationalOfferingBannerImages from '../../../_data/educational_offering_banner_images.json';

const BannerCarrouselEducationOffering = () => {
    return (
        <DemoCarousel images={educationalOfferingBannerImages} />
    );
};

export default BannerCarrouselEducationOffering;