import useImages from "./hooks/useImages";
import PropTypes from "prop-types";

const Images = ({ url }) => {
    const images = useImages(url);
    const elementsImages = images.map((item, index) => <img key={index} className="identity-img" src={item} alt="Logo" />);

    return <figure className="institutional_identity">{elementsImages}</figure>;
};

Images.propTypes = {
    url: PropTypes.string.isRequired,
};

export default Images;
